import React, { useState } from "react";


const About = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className="min-h-screen flex flex-col bg-sunset">
      <div className="flex flex-col items-start text-federal-blue flex-grow ml-[5vw] sm:ml-[20vw] md:ml-[15vw] lg:ml-[20vw]">
        <h1 className="text-4xl font-bold mb-6 mt-[20vw] md:mt-[15vw] lg:mt-[8vw] text-left font-georgia">

        </h1>
          <section className="mb-3 font-courier"> {/* Fixed margin */}
            <p className="text-lg">
            <a
                href="https://www.pairofdice.xyz"
                className="hover:underline relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                aria-label="Visit pairofdice.xyz"
              >
                <strong>pairofdice.xyz</strong>
                {isHovered && (
                  <span
                    className="absolute left-0 -bottom-6 bg-white text-federal-blue p-1 rounded text-sm"
                    role="tooltip"
                  >
                    Blog.
                  </span>
                )}
              </a>                
            </p>
          </section>

          <div className="border-t border-federal-blue w-[50vw] my-6"></div>
        </div>
      </div>
  );
};

export default About;
