import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import FooterWithLinks from './components/FooterWithLinks';
import About from './components/About';
// import Products from './components/Products';
import Contact from './components/Contact';
import './App.css';

// Inline SVG components
const SunHamburger = ({ color }) => (
  <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <circle cx="12" cy="12" r="0" stroke={color} strokeWidth="2"/>
    <path d="M7 12H17" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    <path d="M7 8H17" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    <path d="M7 16H17" stroke={color} strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

function App() {
  const location = useLocation();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Background color based on route
  const getBackgroundColor = useCallback(() => {
    switch (location.pathname) {
      case '/about':
        return '#fccb7eff'; // Sunset
      case '/contact':
        return '#e3e6f6ff'; // Lavender Web
      case '/products':
        return '#0b1ecbff'; // Medium Blue
      default:
        return '#05136bff'; // Federal Blue
    }
  }, [location.pathname]);

  // Text color based on route
  const getNavTextColor = useCallback(() => {
    switch (location.pathname) {
      case '/about':
      case '/contact':
        return 'text-federal-blue';
      case '/products':
      default:
        return 'text-navajo-white';
    }
  }, [location.pathname]);

  // Update background color
  useEffect(() => {
    const bgcolor = getBackgroundColor();
    document.body.style.backgroundColor = bgcolor;
    document.body.style.transition = 'background-color 50ms ease-out';
  }, [getBackgroundColor]);

  // Handle resize event to toggle desktop/mobile state
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle 'Escape' key press to close mobile menu
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isMobileMenuOpen]);

  // Icon color based on route
  const getIconColor = useCallback(() => {
    switch (location.pathname) {
      case '/about':
      case '/contact':
        return '#05136bff'; // federal blue
      case '/products':
      default:
        return '#fddfafff'; // navajo white
    }
  }, [location.pathname]);

  // Hamburger Icon for mobile
  const getSunHamburgerIcon = useCallback(() => {
    return <SunHamburger color={getIconColor()} aria-label="Menu Icon" />;
  }, [getIconColor]);

  // Navigation Links
  const NavLinks = () => (
    <>
      <Link to="/" className={`font-semibold font-georgia hover:italic ${getNavTextColor()}`} onClick={() => setIsMobileMenuOpen(false)}>
        Home
      </Link>
      <Link to="/about" className={`font-semibold font-georgia hover:italic ${getNavTextColor()}`} onClick={() => setIsMobileMenuOpen(false)}>
        About
      </Link>
      {/* <Link to="/products" className={`font-semibold font-georgia hover:italic ${getNavTextColor()}`} onClick={() => setIsMobileMenuOpen(false)}>
        Products
      </Link>       */}
      {/* <Link to="/contact" className={`font-semibold font-georgia hover:italic ${getNavTextColor()}`} onClick={() => setIsMobileMenuOpen(false)}>
        Contact
      </Link> */}
    </>
  );

  return (
    <div className="App min-h-screen flex flex-col" style={{ backgroundColor: getBackgroundColor(), transition: 'background-color 50ms ease-out' }}>
      <div className="container mx-auto px-4 flex-grow flex flex-col">
        {/* Desktop Navigation */}
        {isDesktop && (
          <nav className="fixed top-0 right-8 pt-8 flex flex-row items-center space-x-6" aria-label="Main Navigation">
            <NavLinks />
          </nav>
        )}

        {/* Mobile Navigation */}
        {!isDesktop && (
          <>
            <button
              type="button"
              className={`fixed top-0 right-4 pt-4 z-50 ${getNavTextColor()}`}
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-expanded={isMobileMenuOpen}
              aria-controls="mobile-menu"
              aria-label="Open mobile menu"
            >
              {getSunHamburgerIcon()}
            </button>
            {isMobileMenuOpen && (
              <nav
                id="mobile-menu"
                className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center"
                aria-labelledby="mobile-menu-title"
                role="dialog"
                aria-modal="true"
              >
                <div className="p-8 rounded-md bg-black bg-opacity-50 flex flex-row space-x-4" style={{ backgroundColor: getBackgroundColor() }}>
                  <NavLinks />
                </div>
              </nav>
            )}
          </>
        )}

        {/* Main content area */}
        <main className={`flex-grow transition-opacity duration-100 ${isMobileMenuOpen ? 'opacity-0' : 'opacity-100'}`} aria-hidden={isMobileMenuOpen}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/products" element={<Products />} /> */}
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        {/* Footer */}
        <FooterWithLinks textColor={getNavTextColor()} />
      </div>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;